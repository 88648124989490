import CONFIG from "../config.json";
import TEST_CONFIG from "../configTest.json";

export const getConfig = (test = false) => {
  if (window.location.hash.includes("test")) {
    return TEST_CONFIG;
  }

  return CONFIG;
};
