{
  "CONTRACT_ADDRESS": "0x7CbCC978336624be38Ce0c52807aEbf119081EA9",
  "SCAN_LINK": "https://testnet.bscscan.com/address/0x7CbCC978336624be38Ce0c52807aEbf119081EA9",
  "NETWORK": {
    "NAME": "Binance Smart Chain",
    "SYMBOL": "BNB",
    "ID": 97
  },
  "NFT_NAME": "PLRT Mint Pass 20,000 PLRT",
  "SYMBOL": "Mint Pass",
  "MAX_SUPPLY": 255,
  "WEI_COST": 250000000000000000,
  "DISPLAY_COST": 0.31,
  "GAS_LIMIT": 285000,
  "MARKETPLACE": "TBA",
  "MARKETPLACE_LINK": "TBA",
  "SHOW_BACKGROUND": false,
  "BSCSCAN_API_PRICE": "https://plrtoken.com/price.json"
}
  