{
    "name": "EIP-3540",
    "number": 3540,
    "comment": "EVM Object Format (EOF) v1",
    "url": "https://eips.ethereum.org/EIPS/eip-3540",
    "status": "Review",
    "minimumHardfork": "london",
    "requiredEIPs": [
        3541
    ],
    "gasConfig": {},
    "gasPrices": {},
    "vm": {},
    "pow": {}
}
