{
  "CONTRACT_ADDRESS": "0xa492E89D5bCC766180f572AC8278C67A39cC8032",
  "SCAN_LINK": "https://bscscan.com/token/0xa492e89d5bcc766180f572ac8278c67a39cc8032#readContract",
  "NETWORK": {
    "NAME": "Binance Smart Chain",
    "SYMBOL": "BNB",
    "ID": 56
  },
  "NFT_NAME": "PLRT Mint Pass 20,000 PLRT",
  "SYMBOL": "Mint Pass",
  "MAX_SUPPLY": 255,
  "WEI_COST": 250000000000000000,
  "DISPLAY_COST": 0.31,
  "GAS_LIMIT": 285000,
  "MARKETPLACE": "TBA",
  "MARKETPLACE_LINK": "TBA",
  "SHOW_BACKGROUND": false,
  "BSCSCAN_API_PRICE": "https://plrtoken.com/price.json"
}