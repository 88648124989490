import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { getConfig } from "./config";
import logo from "../assets/logo.svg";
import metamask from "../assets/metamask.svg";
import bscLogo from "../assets/bsc.svg";
import nft3d from "../assets/nft3d.mp4";

export function WithdrawApp() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const videoElementRef = useRef();

  const CONFIG = getConfig();

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };
 
  useEffect(() => {
    getData();
  }, [blockchain.account]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex justify-center">
      <div className="max-w-screen-xl p-10 lg:p-5 w-full flex flex-col justify-between min-h-[100vh]">
        <div>
          <div className="flex justify-between">
            <a href="https://plrtoken.com">
              <img src={logo} alt="logo" className="lg:w-[80px] lg:h-[26px]" />
            </a>
          </div>
          <div className="mt-[2rem] lg:mt-[1.5rem] flex lg:block justify-start">
            <div className="w-1/2 lg:w-full">
              <h1 className="text-2xl lg:text-xl text-secondary font-secondary m-0 mb-[1rem] lg:mb-[1.4rem]">
              Withdraw PLRT Tokens
              </h1>
              {data.balance > 0 && (
                <video
                  ref={videoElementRef}
                  id="video"
                  autoPlay
                  loop
                  playsInline
                  muted
                  className="lg:block hidden max-w-[600px] lg:w-full lg:mx-auto"
                >
                  <source src={nft3d} type="video/mp4" />
                </video>
              )}
              {blockchain.account && !data.totalSupply && (
                <span className="text-secondary">Loading data...</span>
              )}
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <>
                  {blockchain.loading && (
                    <span className="block mt-[2rem] lg:mt-0 text-secondary">
                      Establishing connection.....
                    </span>
                  )}
                  {!blockchain.loading && (
                    <div
                      onClick={() => {
                        dispatch(connect());
                        getData();
                      }}
                      className="rounded-[22px] inline-flex justify-between w-[auto] lg:w-full border-2 border-secondary p-4 group hover:bg-secondary cursor-pointer mr-14 lg:mr-0 "
                    >
                      <div className=" font-secondary text-secondary text-xl leading-tight group-hover:text-primary">
                        <div>CONNECT</div>
                        <div className="text-xs leading-tight group-hover:text-primary font-primary mt-[0.5rem]">
                          Connect Your Web3 Wallet.
                        </div>
                      </div>
                      <div className="flex flex-col justify-between">
                        <div className="text-right leading-zero">
                          <div className="inline-block">
                            <img
                              src={metamask}
                              alt="metamask"
                              className="w-[32px] h-[32px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {blockchain.errorMsg !== "" && (
                    <span className="mt-2.5 block text-secondary">
                      {blockchain.errorMsg}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {data.balance > 0 && (
                    <div className="block text-secondary lg:mt-[4rem]">
                      <h2 className="m-0 mb-[0.25rem] text-base text-secondary">
                        You currently own
                      </h2>
                      <span className="text-white text-xl">
                        {data.balance} PLRT Mint Pass NFTs.
                      </span>
                      <h2 className="m-0 mb-[0.25rem] mt-[1rem] text-base text-secondary">
                        Total Token balance
                      </h2>
                      <span className="text-white text-xl">
                        {data.balance * 20 * 1000} PLRT
                      </span>
                      <h2 className="m-0 mb-[0.25rem] mt-[1rem] text-base text-secondary">
                        The Vesting period
                      </h2>
                      <span className="text-white text-xl">
                        7 Days @ 10% of Total
                      </span>
                      <h2 className="m-0 mb-[0.25rem] mt-[0.5rem] text-base text-secondary">
                        Allowance
                      </h2>
                      <span className="block text-white text-xl">
                        {((data.balance * 20000) / 100) * 10} PLRT every 7 days
                      </span>
                      <div
                        onClick={() => {
                          alert("Locked tokens are available through our Launchpad PinkSale.  The site will open and you can check your balance by connecting with your web3 wallet.\n\nNote: This is the wallet containing your 'PLRT Mint Pass'");
                          window.open('https://www.pinksale.finance/pinklock/bsc/0xFf4eeE3503a0a96660e49B323e0efBAf209b0B13', '_blank');
                        }}
                        className="mt-[2rem] rounded-[22px] inline-flex justify-between w-[252px] lg:w-full border-2 border-secondary p-4 group hover:bg-secondary cursor-pointer "
                      >
                        <div className="w-1/2 font-secondary text-secondary text-xl leading-tight group-hover:text-primary">
                          <div>WITHDRAW</div>
                          <div className="text-xs leading-tight group-hover:text-primary font-primary mt-[0.5rem]">
                            {((data.balance * 20000) / 100) * 10} PLRT
                          </div>
                        </div>
                        <div className="flex flex-col justify-between">
                          <div className="text-right leading-zero">
                            <div className="inline-block">
                              <svg
                                width="32"
                                height="30"
                                viewBox="0 0 32 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-secondary group-hover:fill-primary"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6.80028 0.375H20.1936C26.6978 0.375 31.8327 3.32476 31.8327 9.85627C31.8327 11.7616 31.4169 13.3354 30.6659 14.6133L24.9385 11.2803C25.0819 10.856 25.1574 10.3817 25.1574 9.85577C25.1574 7.11667 23.0604 5.76844 19.8513 5.76844H13.8017H6.80028V11.8241H0.125V0.375H6.80028ZM0.125 13.9833L17.9732 13.9848V10.0893L29.0475 16.5331L17.9732 22.9769V19.0833H6.80028V29.8702H0.125V13.9833Z"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              )
            </div>
            <div className="flex justify-center w-1/2 lg:hidden">
              {data.balance > 0 && (
                <video
                  ref={videoElementRef}
                  id="video"
                  autoPlay
                  loop
                  playsInline
                  muted
                >
                  <source src={nft3d} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </div>
        <div className="flex mt-4 lg:mt-8">
          <a href={CONFIG.SCAN_LINK} target="_blank" className="flex">
            <img
              src={bscLogo}
              alt="BSC Scan"
              className="mr-2.5 w-[24px] h-[24px] self-center"
            />
            <div>
              <span className="text-[0.625rem] text-secondary block">
                BSC Scan Verified Contract:
              </span>
              <span className="text-white text-xs block">
                {CONFIG.CONTRACT_ADDRESS.substr(0, 24)}...
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
