{
    "name": "EIP-3651",
    "number": 3198,
    "comment": "Warm COINBASE",
    "url": "https://eips.ethereum.org/EIPS/eip-3651",
    "status": "Review",
    "minimumHardfork": "london",
    "requiredEIPs": [2929],
    "gasConfig": {},
    "gasPrices": {},
    "vm": {},
    "pow": {}
}
