{
    "name": "EIP-3670",
    "number": 3670,
    "comment": "EOF - Code Validation",
    "url": "https://eips.ethereum.org/EIPS/eip-3670",
    "status": "Review",
    "minimumHardfork": "london",
    "requiredEIPs": [
        3540
    ],
    "gasConfig": {},
    "gasPrices": {},
    "vm": {},
    "pow": {}
}
