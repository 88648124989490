// log
import store from "../store";
import { getConfig } from "../../config";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  const CONFIG = getConfig();

  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const smartChain = store.getState().blockchain.smartContract;

      const [totalSupply, balance, cost, bscScanData] = await Promise.all([
        smartChain.methods.totalSupply().call(),
        smartChain.methods.balanceOf(account).call(),
        smartChain.methods.cost().call(),
        fetch(CONFIG.BSCSCAN_API_PRICE),
      ]);

      dispatch(
        fetchDataSuccess({
          totalSupply,
          balance,
          cost,
          bnbPrice: (await bscScanData.json()).price,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
